import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityOffshoreSupportConnection } from "@graphql-types";
import {
  isSanityCarousel,
  isSanityFullWidthImageBlock,
  isSanityLeftRightBlock,
  isSanityCarouselWithButton,
  isSanityBlockContent,
  isSanityGetInTouchBlock,
} from "@util/types";
import {
  CarouselWithButton,
  DefaultCarousel,
  FullWithImageBlock,
  GetInTouchBlock,
  LatestNewsBlock,
  LeftRightBlock,
  ParagraphBlock,
} from "@global";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityOffshoreSupportConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function OffshoreSupportPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityOffshoreSupport;
  const { offshoreSupportHero, seo, components, latestNewsBlock } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  useRegionChanged("offshore-support", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="offshore-support" alternateRegions={alternateSlugs} heroData={offshoreSupportHero}/>
      <Hero heroData={offshoreSupportHero} />
      {components &&
        components.map((data) => {
          if (isSanityCarousel(data)) {
            return <DefaultCarousel data={data} key={data?._key} />;
          }
          if (isSanityLeftRightBlock(data)) {
            return <LeftRightBlock data={data} key={data?._key} />;
          }
          if (isSanityFullWidthImageBlock(data)) {
            return <FullWithImageBlock data={data} key={data?._key} />;
          }
          if (isSanityBlockContent(data)) {
            return <ParagraphBlock data={data} key={data?._key} />;
          }
          if (isSanityCarouselWithButton(data)) {
            return <CarouselWithButton data={data} key={data?._key} />;
          }
          if (isSanityGetInTouchBlock(data)) {
            return <GetInTouchBlock data={data} key={data?._key} />;
          }
          return null;
        })}
      {latestNewsBlock?.displayToggle && <LatestNewsBlock data={latestNewsBlock} />}
    </div>
  );
}

export const query = graphql`
  query OffshoreSupportPageQuery($iban: String) {
    allSanityOffshoreSupport(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        offshoreSupportHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        components {
          ... on SanityCarousel {
            ...sanityCarousel
          }
          ... on SanityBlockContent {
            ...sanityBlockContent
          }
          ... on SanityFullWidthImageBlock {
            ...sanityFullWidthImageBlock
          }
          ... on SanityLeftRightBlock {
            ...sanityLeftRightBlock
          }
          ... on SanityCarouselWithButton {
            ...sanityCarouselWithButton
          }
          ... on SanityGetInTouchBlock {
            ...sanityGetInTouchBlock
          }
        }
        latestNewsBlock {
          ...sanityLatestNewsBlock
        }
      }
    }
    altRegions: allSanityOffshoreSupport {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
